import React from "react"
import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import cssRest from "normalize.css"
import generic from "@/components/generic-styles"

interface Props {
  children: React.ReactNode
  title?: string | null
  description?: string | null
  image?: string | null
  url?: string | null
  currentPage?: string | null
  noIndex?: boolean
}

const globalCss = css`
  ${cssRest}
  ${generic}
`

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
        keywords
        language
        siteUrl
      }
    }
    shareImage: file(relativePath: { eq: "img/shareImage.png" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          src
        }
      }
    }
  }
`

const imageUrl = (base: string, path: string | null) => {
  if (!path) return null
  return `${base}${path}`
}

const Wrap = styled.div`
  margin: 0 auto;
  width: 1400px;
  max-width: 100%;
  padding: 0 40px;

  @media (max-width: 480px) {
    padding: 0 20px;
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 50px;

  h2 {
    font-size: 36px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 200;
    margin: 0;
    letter-spacing: 3px;
  }

  a {
    text-decoration: none;
  }
`

const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  gap: 30px;
  font-size: 18px;

  @media (max-width: 420px) {
    gap: 15px;
    font-size: 16px;
  }
`

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  transition: color 0.3s, border 0.3s;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: white;
  }

  ${p =>
    p.$isActive &&
    css`
      color: white;
      border-color: white;
    `}
`

const Main = styled.main`
  position: relative;
  margin-bottom: 40px;
`

export default function Layout({
  children,
  currentPage,
  title: customTitle = "",
  description: customDescription,
  image = "/thumb/reel-thumb-x2.jpg",
  url,
  noIndex,
}: Props) {
  const { site, shareImage } = useStaticQuery(query)

  const {
    title,
    description,
    keywords,
    language,
    siteUrl,
    twitterHandle,
  } = site.siteMetadata
  const metaImage = imageUrl(
    siteUrl,
    image || shareImage?.childImageSharp.fixed.src
  )
  const currentUrl = `${siteUrl}${url && "/"}${url || ""}`

  return (
    <>
      <Global styles={globalCss} />
      <Helmet title={customTitle || title}>
        <html lang={language} />
        <title>{customTitle || title}</title>
        <meta name="description" content={customDescription || description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={currentUrl} />
        <link rel="shortcut icon" type="image/png" href="./favicon2.png" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={customTitle || title} />
        <meta
          property="og:description"
          content={customDescription || description}
        />
        {metaImage ? <meta property="og:image" content={metaImage} /> : null}
        <meta property="twitter:card" content="summary_large_image" />
        {twitterHandle && (
          <meta property="twitter:creator" content={twitterHandle} />
        )}
        <meta property="twitter:title" content={customTitle || title} />
        <meta
          property="twitter:description"
          content={customDescription || description}
        />
        {metaImage ? (
          <meta property="twitter:image" content={metaImage} />
        ) : null}
        {noIndex ? <meta name="robots" content="noindex" /> : null}
      </Helmet>

      <Wrap>
        <Header>
          <h2>
            <Link to="/">Igor Husar</Link>
          </h2>
          <Nav>
            <NavLink to="/" $isActive={currentPage === "index"}>
              Work
            </NavLink>
          </Nav>
        </Header>
        <Main>{children}</Main>
      </Wrap>
    </>
  )
}
