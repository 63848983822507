import { css } from "@emotion/core"

export default css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.4;
    background-color: #0f161c;
    color: rgb(218, 227, 228);
    font-weight: 300;
  }

  img {
    max-width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }

  article {
    .gatsby-resp-image-link {
      line-height: 0;
    }

    .gatsby-resp-image-image {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      opacity: 1;
      transition: none 0s ease 0s;
    }

    .gatsby-resp-image-link {
      position: relative;
    }
  }

  #gatsby-noscript {
    color: white;
    background: #4a65ff;
    text-align: center;
    padding: 10px;
    width: 100%;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
`
